@use "../../styles/partials/mixins" as *;

.ham {
  width: 100vw;
  height: 100vh;

  @include desktop {
    width: auto;
    height: auto;
    position: relative;
  }
  
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    margin-top: 96px;
    height: 250px;
    padding: 0;
    justify-content: space-between;
    @include desktop {
      flex-direction: row;
      margin-top: 0;
      height: auto;
      width: 330px;
      margin-bottom: 0;
      position: relative;
      left: 90px;
    }
  }

  &__item {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    @include desktop {
      font-size: .875rem;
      line-height: 1.5rem;
      font-weight: 600;
    }
  }

  &__link {
    text-decoration: none;
    color: black;
    @include desktop {
    }
  }
}

