.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  textarea {
    width: 308px;
    height: 90px;
    background-color: #f9f9f9;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    padding-left: 7px;
    padding-top: 7px;
  }
  &__photo {
    width: 100vmin;
    &--desk {
      display: none;
    }
  }
  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    & h2 {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3.5625rem;
    }
  }
  &__label {
    display: flex;
    flex-direction: column;
    width: 314px;

    label {
      margin: 12px 0px 10px;
    }
    input {
      background-color: #f9f9f9;
      height: 50px;
      border: 1px solid #bdbdbd;
      border-radius: 5px;
      padding-left: 7px;
    }
  }
  &__button {
    background-color: #ff7b47;
    border: none;
    color: #ffffff;
    width: 314px;
    height: 58px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @media (min-width: 1049px) {
    background-color: #f3f3f3;
    textarea {
      width: 562px;
    }
    &__label {
      width: 538px;
    }
    &__button {
      width: 585px;
      font-weight: bold;
    }
    &__bigBox {
      display: flex;
      flex-direction: row;
      margin-top: 150px;
      background-color: #ffffff;
      margin-bottom: 100px;
    }
    &__box {
      margin-left: 50px;
      margin-right: 50px;
    }
    &__photo {
      display: none;
      &--desk {
        display: initial;
        width: 414px;
      }
    }
  }
}
