.services {
  background-color: #f3f3f3;

  &__cardBox {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-block: 100px 70px;
  }
  &__heading {
    margin-bottom: 0px;
    font-weight: 700;
  }
  &__card {
    width: 230px;
    min-height: 320px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-inline: 28px;
    padding-block: 40px;
  }
  @media (min-width: 1049px) {
    height: 474px;
    display: flex;
    justify-content: center;
    &__cardBox {
      flex-direction: row;
      height: 474px;
      padding-top: 183px;
      width: 1049px;
    }
  }
}
