.testimonials {
  color: #ffffff;
  background-color: #17213c;
  &__bigBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__box {
    position: relative;
  }
  &__image {
    height: 96px;
    width: 96px;
    position: absolute;
    top: 28px;
    left: -27.5px;
  }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 262px;
    text-align: center;
    margin-top: 75px;
    & h2 {
      font-size: 2.5rem;
      line-height: 3.5625rem;
      font-weight: 700;
    }
  }
  &__client {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      padding-left: 82px;
    }
    h6 {
      padding-left: 82px;
    }
    &--indi {
      width: 325px;
      height: 408px;
      background-color: #ffffff;
      color: #252b42;
      margin-bottom: 30px;
      padding-top: 30px;
      &__last {
        margin-bottom: 144px;
        width: 325px;
        height: 408px;
        color: #252b42;
        background-color: #ffffff;
      }
    }
  }
  &__para {
    padding-left: 82px;
    color: #737373;
  }
  @media (min-width: 1049px) {
    &__para {
      white-space: normal;
      width: 431px;
      padding-left: 60px;
    }
    &__image {
      left: 25px;
    }
    &__client {
      flex-direction: row;
      margin-bottom: 86px;
      margin-top: 44px;
      overflow-x: auto;
      white-space: nowrap;
      width: 90vw;

      h3 {
        padding-left: 60px;
      }
      h6 {
        padding-left: 60px;
      }

      &--indi {
        width: 490px;
        height: 228px;
        margin-left: 5vw;
        &__last {
          width: 490px;
          height: 228px;
          margin-bottom: 30px;
          margin-left: 5vw;
          margin-right: 5vw;
        }
      }
    }
  }
}
