@use "../../styles/partials/mixins" as *;

.who {
  &__bigBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -21px;
      right: 0;
      left: 0;
      height: 33px;
      background: linear-gradient(180deg, #f3f3f3 74.01%, rgba(217, 217, 217, 0) 99.65%);
      transform: rotate(-180deg);
      z-index: -1;
    }
  }

  @include tablet {
    &__copy {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin-inline: auto;
    }

    &__text {
      font-size: 20px;
      width: 47%;
      &:first-child {
        margin-right: 16px;
      }
    }
  }

  &__text {
    text-align: left;
  }

  &__const {
    width: 100%;
    margin-bottom: 36px;
    border-radius: 4px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;    @include tablet {
      width: 450px;
      margin-bottom: 48px;
    } 
  }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 275px;
    text-align: center;
    padding-block: 24px 24px;
    @include tablet {
      width: auto;
      padding-block: 48px 36px;
    }
    & h2 {
      font-size: 2.5rem;
      line-height: 3.5625rem;
      font-weight: 700;
      margin-bottom: 16px;
      @include tablet {
        margin-bottom: 24px;
      }
      @include desktop {
        font-weight: 700;
      }
    }
    &--second {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 330px;
      text-align: center;
      margin-block: 24px 30px;
      & h2 {
        font-size: 2.5rem;
        line-height: 3.5625rem;
        font-weight: 700;
        @include desktop {
          font-weight: 700;
        }
      }
      & ul {
        list-style: none;
        margin-top: 8px;
        margin-bottom: 0;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        & li {
          font-weight: 700;
        }
      }
    }
  }
  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
