@use "../../styles/partials/mixins" as *;

.nav {
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 5;
  @include desktop {
  }

  &__title {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    @include desktop {
      margin-bottom: 0;
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  &__bigBox {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-top: 3%;
    padding-bottom: 16px;
    background-color: #f7f7f7;
    @include desktop {
      position: fixed;
      opacity: 0.96;
    }
  }

  &__top-container {
    display: flex;
    justify-content: space-between;
    margin-inline: auto;
    width: 83.755%;
    @include desktop {
      align-items: center;
    }
    &--contact {
      margin-left: 21px;
      margin-right: 39px;
    }
  }

  &__hamburgerButton {
    @include desktop {
      color: white;
    }
  }

  @media (min-width: 1049px) {
    
  }
}
