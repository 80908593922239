@use "../../styles/partials/mixins" as *;

.hero {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../Assets/Images/background.png");
  height: 1182px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include desktop {
  }

  &__box {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    margin: 175px 0px 40px 0px;
    width: 230px;
    text-align: center;
    font-size: 40px;
    @include desktop {
      margin-top: 200px;
      width: 635px;
      font-size: 58px;
      padding-top: 30px;
    }
  }
  &__icon {
    margin-right: 8px;
  }
  &__desc {
    width: 279px;
    text-align: center;
    margin-bottom: 40px;
    font-size: 20px;
  }
  &__button {
    width: 185px;
    height: 48px;
    border-radius: 37px;
    background-color: #ff7b47;
    color: #ffffff;
    border: transparent;
    margin-bottom: 80px;
  }
  &__cardBox {
    height: 474px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include desktop {
      position: relative;
      top: 65px;
    }
  }
  &__cards {
    width: 230px;
    height: 96px;
    background-color: #ffffff;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 1049px) {
    height: 793px;
    &__box {
      width: 1049px;
    }
    &__desc {
      width: 539px;
    }
    &__button {
      margin-bottom: 120px;
    }
    &__cardBox {
      flex-direction: row;
      justify-content: space-between;
      height: 96px;
      width: 100%;
      background-color: #ffffff;
    }
  }
}
